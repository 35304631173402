// require( "jquery" );
require('./bootstrap')
import 'slick-carousel'
// import "bootstap"
// import 'owl.carousel';

// require('./bootstrap');

// import * as TweenMax from "gsap/TweenMax";

// Global definitions

let app = $('.app');
let appContent = $('.app .content');
//let preloaderParent = $('.page-preloader');
let hamburger = $(".main-header .hamburger");
let header = $(".main-header");
let menu = $(".main-header .main-menu");
let shadow = $("header .shadow");
let regionSettingsBtn = $('.main-header #global-settings');
let regionSettings = $('.main-header .main-settings');
let mainCarousel = $('.games-carousel .carousel');
let gameGallery = $('.gallery-owl-carousel');
let awardsGallery = $('.awards-carousel');

// Sticky header

$(function() {
    var header = $(".main-header");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 90) {
            header.removeClass('static').addClass("sticky");
        } else {
            header.removeClass("sticky").addClass('static');
        }
    });
});

// Preloader


// setTimeout(function () {
//     $(preloaderParent).toggleClass('disabled');
// }, 1700);

// Hamburger

$(hamburger).on('click', function() {
    $(this).toggleClass('is-active');
    $(menu).toggleClass('is-active');
    $(appContent).toggleClass('blurred');
});

// Region Settings

$(regionSettingsBtn).on('click', function() {
    $(this).toggleClass('is-active');
    $(regionSettings).toggleClass('is-active');
    $(appContent).toggleClass('blurred');
});

// Custom dropdowns

function create_custom_dropdowns() {
    // $('select:not(.normal-select)').each(function(i, select) {
    //     if (!$(this).next().hasClass('dropdown')) {
    //         $(this).after('<div class="dropdown ' + ($(this).attr('class') || '') + '" tabindex="0"><span class="current"></span><div class="list"><ul></ul></div></div>');
    //         var dropdown = $(this).next();
    //         console.log(dropdown);
    //         var options = $(select).find('option');
    //         var selected = $(this).find('option:selected');
    //         dropdown.find('.current').html(selected.data('display-text') || selected.text());
    //         options.each(function(j, o) {
    //             var display = $(o).data('display-text') || '';
    //             dropdown.find('ul').append('<li class="option ' + ($(o).is(':selected') ? 'selected' : '') + '" data-value="' + $(o).val() + '" data-display-text="' + display + '">' + $(o).text() + '</li>');
    //         });
    //     }
    // });
}

// Event listeners

// Open/close
// $(document).on('click', '.dropdown', function(event) {
//     $('.dropdown').not($(this)).removeClass('open');
//     $(this).toggleClass('open');
//     if ($(this).hasClass('open')) {
//         $(this).find('.option').attr('tabindex', 0);
//         $(this).find('.selected').focus();
//     } else {
//         $(this).find('.option').removeAttr('tabindex');
//         $(this).focus();
//     }
// });
// // Close when clicking outside
// $(document).on('click', function(event) {
//     if ($(event.target).closest('.dropdown').length === 0) {
//         $('.dropdown').removeClass('open');
//         $('.dropdown .option').removeAttr('tabindex');
//     }
//     event.stopPropagation();
// });
// // Option click
// $(document).on('click', '.dropdown .option', function(event) {
//     $(this).closest('.list').find('.selected').removeClass('selected');
//     $(this).addClass('selected');
//     var text = $(this).data('display-text') || $(this).text();
//     $(this).closest('.dropdown').find('.current').text(text);
//     $(this).closest('.dropdown').prev('select').val($(this).data('value')).trigger('change');
// });


// Keyboard events
// $(document).on('keydown', '.dropdown', function(event) {
//     var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
//     // Space or Enter
//     if (event.keyCode == 32 || event.keyCode == 13) {
//         if ($(this).hasClass('open')) {
//             focused_option.trigger('click');
//         } else {
//             $(this).trigger('click');
//         }
//         return false;
//         // Down
//     } else if (event.keyCode == 40) {
//         if (!$(this).hasClass('open')) {
//             $(this).trigger('click');
//         } else {
//             focused_option.next().focus();
//         }
//         return false;
//         // Up
//     } else if (event.keyCode == 38) {
//         if (!$(this).hasClass('open')) {
//             $(this).trigger('click');
//         } else {
//             var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
//             focused_option.prev().focus();
//         }
//         return false;
//         // Esc
//     } else if (event.keyCode == 27) {
//         if ($(this).hasClass('open')) {
//             $(this).trigger('click');
//         }
//         return false;
//     }
// });

// $(document).ready(function() {
//     create_custom_dropdowns(); // Creating a custom dropdowns
//     // $(window).on('resize',function(){location.reload();}); // Page Reloading on window resize
// });

// Carousels
//Main Carousel
$(function(){

var time = 7000; //time in ms

function startProgressBar() {
    // apply keyframe animation
    $(".slide-progress").css({
      width: "100%",
      transition: "width " + time + "ms"
    });
  }
  
  function resetProgressBar() {
    $(".slide-progress").css({
      width: 0,
      transition: "width 0s"
    });
  }


$(mainCarousel).owlCarousel({
    items: 1,
    loop: true,
    navigation: false,
    singleItem: true,
    pagination: false,
    slideTransition: 'ease-in-out',
    checkVisible: true,
    // autoPlay: true,
    autoplay: true,
    autoplayTimeout: time,
    // autoplaySpeed: 5000,
    slideSpeed: 500,
    paginationSpeed: 500,
    stagePadding: true,
    nav: true,
    navText: ["<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='44.762' height='37.179' viewBox='0 0 44.762 37.179'>\n" +
        "  <defs>\n" +
        "    <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>\n" +
        "      <stop offset='0' stop-color='#f18916'/>\n" +
        "      <stop offset='1' stop-color='#f7ac05'/>\n" +
        "    </linearGradient>\n" +
        "  </defs>\n" +
        "  <path d='M42.246,56.753l.07.015H12.363l9.416-9.437a2.473,2.473,0,0,0,0-3.477l-1.465-1.466a2.451,2.451,0,0,0-3.459,0L.714,58.527A2.468,2.468,0,0,0,.714,62L16.854,78.14a2.453,2.453,0,0,0,3.459,0l1.465-1.466a2.425,2.425,0,0,0,.714-1.729,2.352,2.352,0,0,0-.714-1.7l-9.522-9.49H42.279a2.537,2.537,0,0,0,2.483-2.511V59.173A2.488,2.488,0,0,0,42.246,56.753Z' transform='translate(0 -41.674)' fill='url(#linear-gradient)'/>\n" +
        "</svg>", "<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='44.762' height='37.179' viewBox='0 0 44.762 37.179'>\n" +
        "  <defs>\n" +
        "    <linearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'>\n" +
        "      <stop offset='0' stop-color='#f18916'/>\n" +
        "      <stop offset='1' stop-color='#f7ac05'/>\n" +
        "    </linearGradient>\n" +
        "  </defs>\n" +
        "  <path id='Path_65' data-name='Path 65' d='M42.246,56.753l.07.015H12.363l9.416-9.437a2.473,2.473,0,0,0,0-3.477l-1.465-1.466a2.451,2.451,0,0,0-3.459,0L.714,58.527A2.468,2.468,0,0,0,.714,62L16.854,78.14a2.453,2.453,0,0,0,3.459,0l1.465-1.466a2.425,2.425,0,0,0,.714-1.729,2.352,2.352,0,0,0-.714-1.7l-9.522-9.49H42.279a2.537,2.537,0,0,0,2.483-2.511V59.173A2.488,2.488,0,0,0,42.246,56.753Z' transform='translate(44.762 78.853) rotate(180)' fill='url(#linear-gradient)'/>\n" +
        "</svg>\n"
    ],
    onInitialized: startProgressBar,
    onTranslate: resetProgressBar,
    onTranslated: startProgressBar
});

});

$(gameGallery).owlCarousel({
    items: 1,
    navigation: false,
    pagination: false,
    slideTransition: 'ease-in-out',
    checkVisible: true,
    autoplaySpeed: 4800,
    margin: 15,
    responsive: {
        568: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 3
        },
        1200: {
            items: 4
        }
    }
});

$(awardsGallery).owlCarousel({
    items: 1,
    navigation: false,
    pagination: false,
    slideTransition: 'ease-in-out',
    checkVisible: true,
    autoplaySpeed: 4800,
    margin: 15,
    responsive: {
        568: {
            items: 2
        },
        768: {
            items: 3
        },
        992: {
            items: 4
        },
        1200: {
            items: 7
        }
    }
});

// Responsive Youtube Embed
$(".product-description iframe").wrap("<div class='video-container'></div>");

// Camera Shake Effect
//
// let amp = 3,
//     cam = document.getElementsByClassName('camera');
//
// TweenMax.set(cam, {scale:1.15, left:-50, top:-50, onComplete:function(){ camScale(); camPan(); }});
//
// function camPan(){
//     new TimelineMax({onComplete:camPan})
//         .to('.camera', rand(0.15,1.1)/amp, {x:rand(0.3,1.5*amp,true), yoyo:true, repeat:1, ease:Power0.easeNone}, 0)
//         // .to('.camera', rand(0.3,1.1)/amp, {y:rand(0.6,1.5*amp,true), yoyo:true, repeat:1, ease:Power0.easeNone}, 0)
//         .to('.game-models', rand(0.7,1.1)/amp, {x:rand(0.5,1.5*amp,true), yoyo:true, repeat:1, ease:Power0.easeNone}, 0)
//         // .to('.game-models', rand(0.7,1.1)/amp, {y:rand(0.5,1.5*amp,true), yoyo:true, repeat:1, ease:Power0.easeNone}, 0)
// }
//
// function camScale(){
//     new TimelineMax({onComplete:camScale})
//         // .to('.camera', rand(0.8,1.2)/amp, {rotation:rand(0,0.1*amp,true), scale:rand(0,0.02*(amp/4),true), yoyo:true, repeat:1, ease:Power0.easeNone}, 0)
//         // .to('.foreground', rand(0.8,1.2)/amp, {rotation:rand(0,0.1*amp,true), scale:rand(0,0.02*(amp/4),true), yoyo:true, repeat:1, ease:Power0.easeNone}, 0)
// }
//
// function rand(min, max, rel) {
//     if (rel) {
//         let sign = "+=";
//         if (Math.random()<0.5) sign = "-=";
//         return sign + String(min + (max-min)*Math.random());
//     }
//     else return min + (max-min)*Math.random();
// }


// change currency or language
// $(document).ready(function() {

//     // currency
//     var submit = $('#change-currency-modal #submit');
//     var cancel = $('#change-currency-modal #cancel');
//     var shopSettings = $('#shop-settings');
//     var changeCurrencyModal = $('#change-currency-modal');
//     var settingCurrency = $('#settings-currency select');
//     var settingCurrencyValue = $('#settings-currency').val();
//     // var textCurrentValue = $('#currency .settings-dropdown .current').text();

//     //language
//     var submitLanguage = $('#change-language-modal #submit');
//     var cancelLanguage = $('#change-language-modal #cancel');
//     var changeLanguageModal = $('#change-language-modal');
//     var settingLanguage = $('#settings-language select');
//     var settingLanguageValue = $('#settings-language').val();
//     // var textCurrentLanguageValue = $('#language .settings-dropdown .current').text();

//     /*
//      * Change currency
//      * */
//     // $('#settings-currency').on('change', function() {
//     //     shopSettings.submit();

//     //     $('#currency .settings-dropdown ul li').removeClass('selected');
//     //     $('#currency .settings-dropdown .current').text(textCurrentValue);


//     //     $('#currency .settings-dropdown ul li').each(function() {
//     //         if ($(this).text() === textCurrentValue) {
//     //             $(this).addClass('selected');
//     //         }
//     //     });

//     //     $(settingCurrency).val(settingCurrencyValue);
//     // });

//     /*
//      * Change language
//      * */
//     // $('#settings-language').on('change', function() {
//     //     shopSettings.submit();
//     // });
// });

// Scroll to top
$(document).ready(function() {
    $('.back-to-top').on('click', function(event) {
        event.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 600, 'swing')
    });
});


// games filter
$(document).ready(function() {
    $('#games_filters').on('change', function() {
        $(this).submit();
    });
});

// bootstrap tooltips
$(function() {
    $('[data-toggle="tooltip"]').tooltip()
})

// add to cart
$(function() {
    $('.product-details-form').submit(function(e) {
        e.preventDefault();

        const $form = $(this);

        $('body').append('<div class="page-preloader" style="position:fixed;top:0;right:0;bottom:0;left:0;background:#000;opacity:.4;display:flex;align-items:center;justify-content:center;z-index:9999;"><div class="lds-dual-ring"></div></div>');

        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            dataType: 'JSON',
            success: function(response) {
                $('.page-preloader').fadeOut();
                $('#product-details-modal-inner').html(response.html);
                $('#product-details-modal').modal('show');
                // reload_cart_watchers();
            },
            error: function(response) {
                $('.page-preloader').fadeOut();
            }
        });
    });

    // $('.add-to-cart-form').submit(function (e) {
    //     e.preventDefault();
    //
    //     const $form = $(this);
    //
    //     $form.find('.unavailable-box').slideUp();
    //
    //     $.ajax({
    //         url: $form.attr('action'),
    //         method: 'POST',
    //         data: $form.serialize(),
    //         dataType: 'JSON',
    //         success: function (response) {
    //             if (response.error) {
    //                 $form.find('.unavailable-box').slideDown();
    //                 console.log(response.error);
    //             } else {
    //                 $('#shopping-cart-count').text(response.count);
    //                 $('#shopping-cart-inner').html(response.html);
    //                 $('#shopping-cart-price').html(response.subtotal);
    //                 $('#shopping-cart').modal('show');
    //                 reload_cart_watchers();
    //             }
    //         }
    //     });
    // });
});


// update cart
function updateQuantity(quantity_form, is_removal = false) {
    if (!quantity_form) {
        return;
    }
    const id = quantity_form.find('input[name=row_id]').val();
    const quantity = (is_removal ? 0 : quantity_form.find('input[name=quantity]').val());

    $('[data-decrease]').attr('disabled', 'disabled');
    $('[data-increase]').attr('disabled', 'disabled');
    $('[data-value]').attr('disabled', 'disabled');

    $.ajax({
        url: quantity_form.attr('action'),
        method: 'POST',
        data: {
            row_id: id,
            quantity: quantity,
        },
        success: function(response) {

            if (response.redirect_to) {
                console.log('ok');
                window.location.replace(response.redirect_to);
            }

            quantity_form.find('input[name=quantity]').val(response.quantity);

            if (response.quantity === '0') {
                quantity_form.closest('.cart__container--item').fadeOut('normal', function() {
                    quantity_form.closest('.cart__container--item').remove();
                });
            }

            const subtotal = response.subtotal;

            if (response.count > 0) {
                $('.alert-cart-empty').fadeOut();
            } else {
                $('.alert-cart-empty').fadeIn();
            }

            var textCurrentValue = $('#dropdownMenuButton').text().trim();
            var textCurrentLanguageValue = $('#dropdownMenuButton1').text().trim();

            var subtotalPriceValue = subtotal;

            $('.subtotal').text(subtotalPriceValue);
            $('#shopping-cart-count').html(response.count);
            $('#shopping-cart-count-inside').html('(' + response.count + ')');
            $('#shopping-cart-price').html(response.subtotal);
            $('[data-decrease]').removeAttr('disabled');
            $('[data-increase]').removeAttr('disabled');
            $('[data-value]').removeAttr('disabled');
            
            if(response.fs_left_amount){
                if(response.fs_left_amount.free) {
                    $('.free-shipping-info').html('<span class="text-success"><strong>' + response.fs_left_amount.message + '</strong></span>');
                } else {
                    if(response.fs_left_amount.error){
                        $('.free-shipping-info').html('<span class="text-danger"><strong>' + response.fs_left_amount.message + '</strong></span>');
                    } else {
                        $('.free-shipping-info').html('<span>' + response.fs_left_amount.message + '</span>');
                    }
                }
            }
        }
    });
}

function reload_cart_watchers() {
    $(function() {
        $('[data-decrease]').unbind().click(function(e) {
            e.preventDefault();

            let value = $(this).parent().find('[data-value]').val();
            if (value > 1) {
                value--;
                $(this).parent().find('[data-value]').val(value);
            }
            updateQuantity($(this).closest('.cart__container--item').find('.quantity-form'));
        });

        $('[data-increase]').unbind().click(function(e) {
            e.preventDefault();

            let value = $(this).parent().find('[data-value]').val();
            if (value < cart_max_single_item_quantity) {
                value++;
                $(this).parent().find('[data-value]').val(value);
            }
            updateQuantity($(this).closest('.cart__container--item').find('.quantity-form'));
        });
        $('[data-value]').unbind().change(function(e) {
            e.preventDefault();

            let value = $(this).val();
            if (value === undefined || isNaN(value) === true || value <= 0) {
                $(this).val(1);
            } else if (value > cart_max_single_item_quantity) {
                $(this).val(cart_max_single_item_quantity);
            }
            updateQuantity($(this).closest('.quantity-form'));
        });

        $('[data-remove]').unbind().click(function(e) {
            e.preventDefault();

            const $form = $(this).closest('.cart__container--item').find('.quantity-form');
            updateQuantity($form, true);
        });
    });
}

$(function() {
    $('#shopping-cart').on('shown.bs.modal', function(e) {
        reload_cart_watchers();
    });
});




$(function() {
    $('.modalIconHref').click(function(e) {
        e.preventDefault();
        const $href = $(this);
        $.ajax({
            url: $href.attr('href'),
            method: 'GET',
            data: $href.serialize(),
            dataType: 'JSON',
            success: function(response) {
                $('#modalBody').html(response.html);
            }
        });
    });
});

/// Redirecty langi
$(document).ready(function() {
    let en_lang_url = $('link[rel="alternate"][hreflang="en"]').attr('href');
    let pl_lang_url = $('link[rel="alternate"][hreflang="pl"]').attr('href');
    let fr_lang_url = $('link[rel="alternate"][hreflang="fr"]').attr('href');

    if (en_lang_url) {
        $('.en_lang-link').attr('data-tag', en_lang_url);
    }

    if (pl_lang_url) {
        $('.pl_lang-link').attr('data-tag', pl_lang_url);
    }

    if (fr_lang_url) {
        $('.fr_lang-link').attr('data-tag', fr_lang_url);
    }
});
// $(document).ready(function () {
//     $('#settings-language').unbind().change(function () {
//         const url = $(this).find('option:selected').attr('data-tag');
//         window.location.href = url;
//     });
// });

$(function() {
    $('.game-img-details').on('click', function(e) {
        e.preventDefault();
        $(this).closest('form').submit();
    });
});

reload_cart_watchers();
reload_cart_watchers();
reload_cart_watchers();

$(function () {
    $(".slider").slick({
        "dots": true,
        "prevArrow": '<button type="button" class="slick-prev"><img src="https://website.cdn77.luckyduckgames.com/assets/img/arrow.png"></button>',
        "nextArrow": '<button type="button" class="slick-next"><img src="https://website.cdn77.luckyduckgames.com/assets/img/arrow.png"></button>',
        "autoplay": true,
        "autoplaySpeed": 6000,
        "speed": 500
    });

    $(".featured-product-slider").slick({
        "dots": true,
        "arrows": false,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "centerMode": false,
        "infinite": false,
        "responsive": [
            {
                "breakpoint": 992,
                "settings": {
                    "slidesToShow": 2,
                }
            },
            {
                "breakpoint": 480,
                "settings": {
                    "slidesToShow": 1,
                }
            }
        ]
    });

    $('.expand-menu').on('click', function(){
        let parent = $(this).parent();
        console.log(parent.closest('ul'));
        console.log('ok');
    });

    $('.dropdown-toggle').dropdown();
    $('.dropdown-toggle').on('click', function(){
        console.log('click');
    })

    // $('#add-to-cart-button:disabled').tooltip();

    let searchInput = $('#search-form input');
    let searchForm = $('#search-form');

    $('#btn-search').on('click', function(){
        if(searchInput.val() == '')
        {
            searchInput.toggleClass('show');
            searchInput.focus();
        }
        else
        {
            searchForm.submit();
        }
    });

    let searchInputMobile = $('#search-form-mobile input.input-mobile');
    let searchFormMobile = $('#search-form-mobile');

    $('#btn-search-mobile').on('click', function(){
        if(searchInputMobile.val() == '')
        {
            searchInputMobile.toggleClass('show');
            searchInputMobile.focus();
        }
        else
        {
            searchFormMobile.submit();
        }
    });
});